export default function AboutBlock () {
    return(
        <div className="about_block">
            <div className="about_block_h">НЕМНОГО О НАС</div>
            <div className="about_block_text">
            В нашем магазине для любого найдется сувенир, наполненный особой <span>энергетикой</span> <br />
Изделия ручной работы принесут в дом уют и приятные эмоции, ведь наша сплоченная команда мастеров подходит к каждой детали своей работы со всем вниманием и кропотливостью
            </div>
            <div className="about_block_imgs">
                <div className="about_block_imgl"></div>
                <div className="about_block_imgr"></div>
            </div>
        </div>
    );
}