import {NavLink} from 'react-router-dom';

export default function Footer() {
    return(
        <div className="footer">
            <div className="footer_inner">
                <div className="footer_item">
                    <div className="footer_h">ссылки</div>
                    <div className="footer_icons">
                        <a href="" className="footer_icon"></a>
                        <a href="" className="footer_icon"></a>
                    </div>
                </div>
                <div className="footer_item">
                    <div className="footer_h">наши контакты</div>
                    <a href="tel:+7800888888" className="footer_link">
                        <div className="footer_imga tel"></div>
                        <div className="footer_texta">+7(999)881-76-25</div>
                    </a>
                    <a href="mailto:podarki@gmail.com" className="footer_link">
                        <div className="footer_imga mail"></div>
                        <div className="footer_texta">yyesichko@mail.ru</div>
                    </a>
                </div>
                <div className="footer_item">
                    <div className="footer_h">Информация</div>
                    <NavLink to="/about">О нас</NavLink>
                    <NavLink to="/about">Наш адрес</NavLink>
                    <NavLink to="/about">Доставка и оплата</NavLink>
                </div>
            </div>
        </div>
    );
} 