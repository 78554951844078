import BasketThing from "./BasketThing";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Cookies from 'universal-cookie';

export default function Basket() {
    const cookies = new Cookies(null, { path: '/' });
    const [checked, setChecked] = useState(true);
    const [basketTick, setBasketTick] = useState({backgroundColor: "transparent"});
    var loc = useLocation();
    var things = [];
    var indexes = cookies.get("indexes");
    var s = 0, item, n = 0;
    if (indexes !== undefined) {
        indexes = indexes.split(";");
        for (let i = 1; i < indexes.length; i++) {
            things.push(<BasketThing key={indexes[i]} productId={indexes[i]}/>);
            item = cookies.get(indexes[i]);
            s += item.amount * item.price;
            n += item.amount;
        }
    }

    const [sum, setSum] = useState(s);
    const [num, setNum] = useState(n);

    console.log("Все куки", cookies.getAll());
    console.log("Все индексы", indexes);
    console.log("Все товары", things);

    const [allThings, setThings] = useState(things);

    function changeChecked() {
        setChecked(!checked);
        if (checked) {
            setBasketTick({backgroundColor: "#35322A", border: "none"})
        } else {
            setBasketTick({background: "none", border: "1px solid #000"})
        }
        var inputs = document.querySelectorAll('input[type="checkbox"]');
        if (indexes !== undefined) {
            for (let i = 0; i < inputs.length; i++) {
                console.log("Товар", inputs[i]);
                inputs[i].checked = checked;
            }
            console.log("Все куки", cookies.getAll());
        }
    }    

    function del() {
        if (cookies.get("number") !== undefined) {
            var indexStr = undefined;
            var indexesNew = [undefined];
            var newThings = [];
            n = 0;
            var inputs = document.querySelectorAll('input[type="checkbox"]');
            var amounts = document.getElementsByClassName("number");
            console.log(inputs);
            for (let i = 0; i < inputs.length; i++) {
                console.log(inputs[i].checked);
                if (!inputs[i].checked) {
                    indexStr += (";" + indexes[i + 1]);
                    indexesNew.push(indexes[i + 1]);
                    newThings.push(things[i]);
                    n += parseInt(amounts[i].innerHTML);
                } else {
                    console.log("Количество товара", (cookies.get(indexes[i + 1])).amount);
                }
            }
            indexes = indexesNew;
            things = newThings;
            if (things.length === 0) n = 0;
            setThings(things);
            cookies.set("indexes", indexStr, { path: '/' });
            cookies.set("number", n, { path: '/' });
            console.log("Индексы", indexes);
            console.log("Товары", things);
            console.log("Все куки", cookies.getAll());
        }
    }

    useEffect(() => {
        const cookieChangeListener = () => {
            console.log("Изменилось количество товаров");
            var indexes = cookies.get("indexes");
            s = 0;
            n = 0;
            var amounts = document.getElementsByClassName("number");
            if (indexes !== undefined) {
                indexes = indexes.split(";");
                for (let i = 1; i < indexes.length; i++) {
                    item = cookies.get(indexes[i]);
                    s += parseInt(amounts[i - 1].innerHTML) * parseInt(item.price);
                    console.log(amounts[i - 1].innerHTML, item.price);
                    n += parseInt(amounts[i - 1].innerHTML);
                }
            }
            setSum(s);
            setNum(n);
        }
        cookies.addChangeListener(cookieChangeListener);
        return () => {
            cookies.removeChangeListener(cookieChangeListener);
        }
    }, [loc]);

    return(
        <div className="basket">
            <form className="basket_inner">
                <div className="basket_top">
                    <div className="basket_top_inner">
                        <div className="basket_top_h">корзина</div>
                        <div className="basket_top_btns">
                            <div className="basket_btn_all" onClick={changeChecked}><div style={basketTick}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="15" viewBox="0 0 18 15" fill="none">
  <path fillRule="evenodd" clipRule="evenodd" d="M6.25844 11.7173L16.4494 0.953766L18 2.59152L6.25844 14.9929L0 8.38275L1.55063 6.74499L6.25844 11.7173Z" fill="#FAFFFD"/>
</svg></div>Выбрать всё</div>
                            <div className="basket_btn_del" onClick={del}><span><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 21" fill="none">
<path d="M9.99998 3.24037C9.18456 3.24037 8.48923 3.76097 8.23154 4.48969C8.1165 4.81497 7.75943 4.98547 7.43398 4.8705C7.10853 4.75552 6.93795 4.39862 7.05299 4.07334C7.48168 2.86105 8.6384 1.991 9.99998 1.991C11.3616 1.991 12.5183 2.86105 12.9471 4.07334C13.0621 4.39862 12.8915 4.75552 12.5661 4.8705C12.2406 4.98547 11.8835 4.81497 11.7685 4.48969C11.5108 3.76097 10.8154 3.24037 9.99998 3.24037Z" fill="#35322A"/>
<path d="M2.2915 5.94766C2.2915 5.60249 2.57133 5.32266 2.9165 5.32266H17.0833C17.4284 5.32266 17.7083 5.60249 17.7083 5.94766C17.7083 6.29284 17.4284 6.57266 17.0833 6.57266H2.9165C2.57133 6.57266 2.2915 6.29284 2.2915 5.94766Z" fill="#35322A"/>
<path d="M4.9293 7.9881C4.90634 7.64385 4.60852 7.3834 4.26411 7.40634C3.9197 7.42929 3.65911 7.72696 3.68207 8.0712L4.06828 13.8614C4.13952 14.9299 4.19707 15.7928 4.33205 16.4701C4.47238 17.1741 4.71106 17.7623 5.20406 18.2232C5.69706 18.6842 6.30001 18.8831 7.01222 18.9762C7.69726 19.0658 8.56265 19.0658 9.63398 19.0658H10.3663C11.4376 19.0658 12.303 19.0658 12.9881 18.9762C13.7002 18.8831 14.3032 18.6842 14.7962 18.2232C15.2892 17.7623 15.5279 17.1741 15.6682 16.4701C15.8031 15.7928 15.8607 14.9299 15.932 13.8614L16.3182 8.0712C16.3411 7.72696 16.0806 7.42929 15.7361 7.40634C15.3917 7.3834 15.0939 7.64385 15.071 7.9881L14.6876 13.7345C14.6128 14.8571 14.5594 15.6382 14.4423 16.226C14.3286 16.7961 14.1701 17.0979 13.9422 17.3109C13.7144 17.5239 13.4026 17.6621 12.8259 17.7374C12.2314 17.8152 11.4481 17.8164 10.3224 17.8164H9.6779C8.55223 17.8164 7.76886 17.8152 7.17436 17.7374C6.59769 17.6621 6.28589 17.5239 6.05804 17.3109C5.8302 17.0979 5.67159 16.7961 5.55796 16.226C5.44082 15.6382 5.38746 14.8571 5.31259 13.7345L4.9293 7.9881Z" fill="#35322A"/>
<path d="M7.85436 9.49033C8.19783 9.45601 8.50406 9.70647 8.53848 10.0498L8.95514 14.2144C8.98948 14.5576 8.73889 14.8638 8.39539 14.8981C8.05194 14.9324 7.74566 14.682 7.71132 14.3386L7.29465 10.1741C7.2603 9.83082 7.51089 9.52464 7.85436 9.49033Z" fill="#35322A"/>
<path d="M12.7051 10.1741C12.7395 9.83082 12.4889 9.52464 12.1454 9.49033C11.802 9.45601 11.4957 9.70647 11.4613 10.0498L11.0447 14.2144C11.0103 14.5576 11.2609 14.8638 11.6044 14.8981C11.9478 14.9324 12.2541 14.682 12.2885 14.3386L12.7051 10.1741Z" fill="#35322A"/>
</svg></span>Удалить</div>
                        </div>
                    </div>
                </div>
                <div className="basket_bottom">
                    <div className="basket_things">{allThings}</div>
                    <div className="basket_bottom_info">
                        <div className="basket_bottom_h">Итого</div>
                        <div className="basket_bottom_amount">Товаров: {num}</div>
                        <div className="basket_bottom_sum">{sum} руб.</div>
                        <a className="basket_bottom_btn" href="/form">Оформить заказ</a>
                    </div>  
                </div>
            </form>
        </div>
    );
}