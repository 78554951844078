import { useEffect, useState } from "react";
import getImage from "../../GetImage";
import Cookies from 'universal-cookie';

export default function BasketThing(props) {
    const cookies = new Cookies(null, { path: '/' });
    var data;
    data = cookies.get(parseInt(props.productId));
    console.log("Данные", data);
    const [number, setNumber] = useState(data === undefined ? 1 : data.amount);
    function changeNumber(l) {
        console.log("MinusPlus");
        if (l) {
            if (number !== 1) {
                cookies.set("number", isNaN(parseInt(cookies.get("number"))) ? 1 : parseInt(cookies.get("number")) - 1, { path: '/' });
                setNumber((number - 1));
            } else {
                setNumber(1);
            }
        }
        else {
            cookies.set("number", isNaN(parseInt(cookies.get("number"))) ? 2 : parseInt(cookies.get("number")) + 1, { path: '/' });
            setNumber(number + 1);
        }
        data.amount = number;
        cookies.set(parseInt(props.productId), data, { path: '/' });
    }

    const img = {backgroundImage: `url("${getImage(data.preview_image)}")`, };

    return(
        <div className="basket_thing">
            <div className="basket_thing_inner">
                <div className="basket_tick">
                    <input id={"input" + props.productId} type="checkbox" className="tick_input"/>
                    <label htmlFor={"input" + props.productId} className="basket_tick_inner">
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="15" viewBox="0 0 18 15" fill="none">
                            <path fillRule="evenodd" clipRule="evenodd" d="M6.25844 11.4907L16.4494 0.727165L18 2.36492L6.25844 14.7663L0 8.15615L1.55063 6.51839L6.25844 11.4907Z" fill="#FAFFFD"/>
                        </svg>
                    </label>
                </div>
                <div className="basket_thing_right">
                    <div className="basket_thing_img" style={img}></div>
                    <div className="basket_thing_info">
                        <div className="basket_thing_main">
                            <div className="basket_thing_h">{data.name}</div>
                            <div className="basket_thing_params">
                                <span>{data.description !== "" && data.description !== " " && data.description !== null ? "Описание: " + data.description : ""}</span>
                                <span>{data.width !== 0 ? "Ширина: " + data.width : ""}</span>
                                <span>{data.height !== 0 ? "Высота: " + data.height : ""}</span>
                                <span>{data.length !== 0 ? "Длина: " + data.length : ""}</span>
                                <span>{data.weight !== 0 ? "Вес: " + data.weight : ""}</span>
                            </div>
                            <div className="basket_thing_amount">
                                <div className="minus" onClick={() => changeNumber(true)}>-</div>
                                <div className="number">{number}</div>
                                <div className="plus" onClick={() => changeNumber(false)}>+</div>
                            </div>
                        </div>
                        <div className="basket_thing_price">{data.price}</div>
                    </div>
                </div>
            </div>
        </div>
    );
}