import './App.css';
import './main.sass';
import Header from './components/Header';
import Footer from './components/Footer';
import Main from './components/Main/Main';
import About from './components/About/About';
import Things, {Category} from './components/Things';
import ThingPage from './components/ThingPage';
import Basket from './components/Basket/Basket';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import 'react-router';
import 'history';
import Cookies from 'universal-cookie';
import Form from './components/Basket/Form';

function App() {
  // const cookies = new Cookies(null, { path: '/' });
  // cookies.remove("number", { path: '/' });
  // for (let i = 0; i < 196; i++)
  //   cookies.remove(i, { path: '/' });
  // cookies.remove("things", { path: '/' });
  // cookies.remove("indexes", { path: '/' });
  // cookies.remove("is_checked", { path: '/' });
  // cookies.remove("amount", { path: '/' });
  // console.log(cookies.getAll());
  return (
    <div className="App">
      <Router>
        <div>
          <Header />
          <Routes>
            <Route path="/" element={<Main />}/>
            <Route path="/things" element={<Things />}>
              <Route index element={<h2>Страница товаров</h2>}/>
              <Route path=":category" element={< Category/>}/>
            </Route>
            <Route path="/thing/:id" element={<ThingPage/>}/>
            <Route path="/about" element={<About/>}/>
            <Route path="/basket" element={<Basket/>}/>
            <Route path="/form" element={<Form/>}/>  
          </Routes>
          <Footer /> 
        </div>
      </Router> 
    </div>
  );
}
const data = document.getElementById('data');
data.style.display = "none";
// data.style.float = "left";

export default App;