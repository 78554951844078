import { useState } from "react";
function FirstCat(props) {
    return(
        <div className={`first_itemcat ${props.className}`}>
            <div className="first_itemcatinner" style={{backgroundImage: `url("${props.imgLink}")`}}>
                <div className="first_itemcath">{props.cat}</div>
                <div className="first_itemcata"><a href={props.href}>Посмотреть все&gt;</a></div>
            </div>
        </div>
    );
}

export default function First() {
    const [index, setIndex] = useState(4);
    function leftClick() {
        console.log("right");
        if (index==2) setIndex(6);
        else setIndex(index-1);
        
    }

    function rightClick() {
        console.log("left");
        if (index==6) setIndex(2);
        else setIndex(index+1);
    }

    return(<div className="first">
        <div className="first_inner">
            <div className="first_btns">
                <div className="first_btn" onClick={leftClick}>
                    <span className="first_btn_img">&lt;</span>
                </div>
                <div className="first_btn first_btn_r" onClick={rightClick}>
                   <span className="first_btn_img">&gt;</span>
                </div>
            </div>
            <div className="first_slider" style={{left: -((index - 2) * 50 + 25) + "%"}}>
                <FirstCat cat="свечи" imgLink="candles.jpg" className="light" href="/things/candles"/>
                <FirstCat cat="керамика" imgLink="ceram.jpg" className="light" href="/things/ceramics"/>
                <FirstCat cat="мыло" imgLink="soap.jpg" href="/things/soap"/>
                <div className="first_itemvid first_itemcat">
                    <div className="first_itemvidinner">
                        <video autoPlay={true} loop muted controls className="bgvideo" id="bgvideo" >
                            <source src={"video.mov"} type="video/mp4"></source>
                        </video>
                    </div>
                </div>
                <FirstCat cat="свечи" imgLink="candles.jpg" className="light" href="/things/candles"/>                
                <FirstCat cat="игрушки" imgLink="soft.jpg" href="/things/soft"/>
                <FirstCat cat="керамика" imgLink="ceram.jpg" className="light" href="/things/ceramics"/>
            </div>
        </div>
    </div>);
}