import {NavLink, useLocation} from 'react-router-dom';
import Cookies from 'universal-cookie';
import { useEffect, useState } from 'react';

export default function Header() {
    const cookies = new Cookies(null, { path: '/' });
    var n = isNaN(parseInt(cookies.get("number"))) ? 0 : parseInt(cookies.get("number"));
    const [number, setNumber] = useState(n);
    var loc = useLocation();
    useEffect(() => {
        const cookieChangeListener = () => {
            n = isNaN(parseInt(cookies.get("number"))) ? 0 : parseInt(cookies.get("number"));
            setNumber(n);
            if (number < 0 || cookies.get("indexes") === undefined || cookies.get("indexes").split(";").length === 1) {
                setNumber(0);
            }
        }
        cookies.addChangeListener(cookieChangeListener);
        return () => {
            cookies.removeChangeListener(cookieChangeListener);
        }
    }, [loc]);
    return(
        <div className="header">
            <nav className="header_inner">
                <div className="header_top">
                    <NavLink to="/" className="header_top_a"></NavLink>
                </div>
                <div className="header_bottom">
                    <div className="header_bottom_inner">
                        <NavLink to="/things/ceramics">Керамика</NavLink>
                        <NavLink to="/things/soap">Мыло</NavLink>
                        <NavLink to="/things/candles">Свечи</NavLink>
                        <NavLink to="/things/soft">Игрушки</NavLink>
                        <NavLink to="/about">О нас</NavLink>
                        <NavLink to="/basket" className="header_right"><div className='header_right_inner'><span id="header_number">{number}</span></div></NavLink>
                    </div>
                </div>
            </nav>
        </div>
    );
}