import { useState } from 'react';
import {useParams, useLocation} from 'react-router-dom';
import getImage from '../GetImage';
import { useEffect } from 'react';
import axios from 'axios';
import Cookies from 'universal-cookie';

export default function ThingPage() {
    const params = useParams();
    const [error, setError] = useState(null);
    const [ready, setReady] = useState(false);
    const [data, setData] = useState([]);
    const loc = useLocation();
    const [btnText, setBtn] = useState("Купить");
    const cookies = new Cookies(null, { path: '/' });
    function buy() {
        if (data.amount == undefined) data.amount = 0;
        data.amount++;
        cookies.set(params.id, data, { path: '/' });
        var l = true;
        if (cookies.get("indexes") !== undefined) {
            var indexes = cookies.get("indexes").split(";"); 
            for (let i = 1; i < indexes.length; i++) {
                if (params.id === indexes[i]) l = false;
            }
        }
        if (l)
            cookies.set("indexes", cookies.get("indexes") + ";" + params.id.toLocaleString(), { path: '/' });
        cookies.set("number", isNaN(parseInt(cookies.get("number"))) ? 1 : parseInt(cookies.get("number")) + 1, { path: '/' });
        setBtn("Товар в корзине");
    }

    useEffect(function () {
        var cookieData = cookies.get(params.id);
        if (isNaN(cookieData)) {
            console.log("Получаем товар с сервера");
            axios.get(`/api/product/${params.id}`).then((res) => {
                setReady(true);
                setData(res.data);
            },
            (error) => {
                setError(error);
                setReady(false);
            })
        } else {
            console.log("Товар в куках");
            setData(cookieData);
        }
    }, [loc]);
    if (ready) return(
        <div className="thing_page">
            <div className="thing_page_inner">
                <div className="athing_img" style={{backgroundImage: `url("${getImage(data.preview_image)}")`}}></div>
                <div className="athing_info">
                    <div>
                        <div className="athing_name">{data.name}</div>
                        <div className="athing_price">{data.is_available ? data.price + " руб." : "Товара нет в наличии"}</div>
                    </div>
                    <div>
                        <div className="athing_param">{data.description !== "" && data.description !== " " && data.description !== null ? "Описание: " + data.description : ""}</div>
                        <div className="athing_param">{data.width !== 0 ? "Ширина: " + data.width : ""}</div>
                        <div className="athing_param">{data.height !== 0 ? "Высота: " + data.height : ""}</div>
                        <div className="athing_param">{data.length !== 0 ? "Длина: " + data.length : ""}</div>
                        <div className="athing_param">{data.weight !== 0 ? "Вес: " + data.weight : ""}</div>
                    </div>
                    <div>
                    <div className="athing_btn" onClick={buy}>{btnText}<span>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 51 8" fill="none">
    <path d="M50.3536 4.35356C50.5488 4.1583 50.5488 3.84171 50.3536 3.64645L47.1716 0.46447C46.9763 0.269208 46.6597 0.269208 46.4645 0.46447C46.2692 0.659732 46.2692 0.976315 46.4645 1.17158L49.2929 4L46.4645 6.82843C46.2692 7.02369 46.2692 7.34028 46.4645 7.53554C46.6597 7.7308 46.9763 7.7308 47.1716 7.53554L50.3536 4.35356ZM-4.37114e-08 4.5L50 4.5L50 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z" fill="#FAFFFD"/>
    </svg></span></div>
                        <div className="athing_last">Товар может несущественно отличаться <br /> от фотографии на сайте </div>
                    </div>
                </div>
            </div>
        </div>
    );
    else return(<p>{error}</p>);
}