import getImage from '../GetImage';
export default function Product(props) {
    const name = props.data.name;
    const price = props.data.price  + " руб.";
    const img = {backgroundImage: `url("${getImage(props.data.preview_image)}")`};
    const href = "/thing/" + props.data.product_id;
    return(
        <a className="thing" href={href}>
            {console.log("Рендеринг товара", name)}
            <div className="thing_inner">
                <div className="thing_img" style={img}>
                </div>
                <div className="thing_name">{name}</div>
                <div className="thing_price">{price}</div>
            </div>
        </a>
    );
}