import Thing from '../Thing';
export default function MainThings(props) {
    let data = props.data;
    let linksItems = data.map((item) => {return(<Thing key={item.product_id} data={item}/>)});
    console.log("LinksItems", linksItems);
    return(
        <div className="main_things">
            <div className="main_things_inner">
                {console.log("Айтемы")}
                {linksItems}
            </div>
        </div>
    );
}