export default function AboutLocation() {
    return(
        // <div className="vlada">
        //     <div className="vlada_img"></div>
        //     <div className="vlada_img"></div>
        //     <div className="vlada_img"></div>
        //     <div className="vlada_img"></div>
        //     <div className="vlada_img"></div>
        //     <div className="vlada_img"></div>
        //     <div className="vlada_img"></div>
        //     <div className="vlada_img"></div>
        //     <div className="vlada_img"></div>
        //     <div className="vlada_img"></div>
        //     <div className="vlada_img"></div>
        //     <div className="vlada_img"></div>
        //     <div className="vlada_img"></div>
        //     <div className="vlada_img"></div>
        //     <div className="vlada_img"></div>
        //     <div className="vlada_img"></div>
        //     <div className="vlada_img"></div>
        //     <div className="vlada_img"></div>
        //     <div className="vlada_img"></div>
        //     <div className="vlada_img"></div>
        // </div>
        <div className="about_location">
            <div className="inner">
                <div className="text">
                    <div className="h">
                        <div className="h_text">Ждём вас в гости
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 181 35" fill="none">
<path d="M180.173 1.46913C180.432 1.3736 180.565 1.08612 180.469 0.827026C180.374 0.567934 180.086 0.435342 179.827 0.530873L180.173 1.46913ZM90.5 34L90.327 34.4691L90.5 34.5329L90.673 34.4691L90.5 34ZM0.827026 1.46913L90.327 34.4691L90.673 33.5309L1.17297 0.530873L0.827026 1.46913ZM90.673 34.4691L180.173 1.46913L179.827 0.530873L90.327 33.5309L90.673 34.4691Z" fill="#35322A"/>
</svg>
                        </div>
                    </div>
                    <div className="point">
                        <div className="img">
                        <svg className="loc" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 21" fill="none">
<path fillRule="evenodd" clipRule="evenodd" d="M7 0.28772C3.13 0.28772 0 3.42335 0 7.30031C0 12.5598 7 20.3237 7 20.3237C7 20.3237 14 12.5598 14 7.30031C14 3.42335 10.87 0.28772 7 0.28772ZM7 9.80481C5.62 9.80481 4.5 8.68279 4.5 7.30031C4.5 5.91783 5.62 4.79581 7 4.79581C8.38 4.79581 9.5 5.91783 9.5 7.30031C9.5 8.68279 8.38 9.80481 7 9.80481Z" fill="#35322A"/>
</svg>
                        </div>
                        <div className="info">По адресу Московская область, город Красногорск,
Красногорский бульвар, 4Б </div>
                    </div>
                    <div className="point">
                        <div className="img phone"></div>
                        <div className="info">+7(999)881-76-25</div>
                    </div>
                    <div className="time">
                        <div className="time_h">Часы работы:</div>
                        <div className="time_info">С 10:00 до 21:00 <br />
                        </div>
                    </div>
                </div>
                <div className="map"><iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3A4d717222a51cc48d1959c6d87e6a1439c9900fdc669e052de81f289472ffbdd0&amp;source=constructor"></iframe></div>
            </div>
        </div>
    );
}