import First from './First';
import MainThings from './MainThings';
import About from '../About/About';
import { useState } from 'react';
import {useParams, useLocation} from 'react-router-dom';
import { useEffect } from 'react';
import axios from 'axios';
export default function Main() {
    const params = useParams();
    const [error, setError] = useState(null);
    const [ready, setReady] = useState(false);
    const [data, setData] = useState([]);
    const loc = useLocation();
    useEffect(function () {
      axios.get(`/api/product/?product=Ceramic`).then((res) => {
        setReady(true);
        var d = [];
        for (let i = 0; i < res.data.length; i++) {
          if (res.data[i].is_available)
            d.push(res.data[i]);
        }
        setData(d);
      },
      (error) => {
        setError(error);
        setReady(false);
      })
    }, [loc]);
    return(
        <div className="main">
            <First/>
            <div className="main_h">рейтинги продаж</div>
            <MainThings data={JSON.parse(JSON.stringify(data.slice(0, 8)))}/>
            <About/>
        </div>
    );
}