import { useState } from 'react';
import {useParams, Outlet, useLocation} from 'react-router-dom';
import { useEffect } from 'react';
import axios from 'axios';
import Thing from './Thing';

export default function Things() {
    return(
      <div className="things">
        <Outlet /> 
      </div> 
    );
}

export function Category() {
    const cats = {"soap": "Soap",
    "soft": "Toy",
    "candles": "Candle",
    "ceramics": "Ceramic"
    };
    const params = useParams();
    const [error, setError] = useState(null);
    const [ready, setReady] = useState(false);
    const [showedItems, setShowed] = useState([]);
    const [pages, setPages] = useState([]);
    const loc = useLocation();
    var item;
    var items = [], p = [];
    var k = 0;

    function changePage(n) {
      var s = document.getElementsByClassName("page");
      for (let i = 0; i < s.length; i++) {
        if (n == i) {
          s[i].style.fontWeight = 600;
          s[i].style.textDecoration = "underline";
        } else {
          s[i].style.fontWeight = 300;
          s[i].style.textDecoration = "none";
        }
      }
      setShowed(items.slice(8 * n, Math.min(8 * n + 8, items.length)));
      window.scroll(0, document.getElementsByClassName("header")[0].clientHeight); 
    }

    useEffect(function () {
      axios.get(`/api/product/?category=${cats[params.category]}`).then((res) => {
        setReady(true);
        k = 0;
        items = [];
        for(let i = 0; i < res.data.length; i++) {
          item = res.data[i];
          if (item.is_available) {
            items.push(item);
          }
        }
        items = items.map((it) => {return(<Thing key={it.product_id} data={it}/>)});
        if (items.length != 0) {
          setShowed(items.slice(8 * k, Math.min(8 * k + 8, items.length)));
          p = [];
          for (let i = 1; i < items.length / 8; i++) p.push(i);
          if (items.length % 8 != 0) p.push(p.length + 1);
          p = p.map((s) => {return(<span key={s} id={"page" + s} onClick={() => changePage(s - 1)} className={s == k + 1 ? "page selected" : "page"}>{s}</span>)});
          setPages(p);
        } else {
          setShowed("Нет товаров");
          setPages("1");
        }
      },
      (error) => {
        setError("Нет товаров");
        setShowed("Нет товаров");
        setReady(false);
      })
    }, [loc]);
    if (ready) {
      return(
        <div className="main_things">
            <div className="main_things_inner">
                {showedItems}
            </div>
            <div className="main_things_pages">
              {pages}
            </div>
        </div>
      );
    }
    return(
      <p>{error}</p>
    );
}