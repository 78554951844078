import Cookies from 'universal-cookie';
import axios from 'axios';
import { useState } from 'react';
export default function Form() {
    const cookies = new Cookies(null, { path: '/' });
    const [btnText, setBtn] = useState("Отправить");
    var formAppearance = true; 
    function formChanger() {
        var basket_form = document.getElementById("basket_form");
        var basket_letter = document.getElementById("basket_letter");
        if (formAppearance) {
            basket_form.style.display = "none";
            basket_letter.style.display = "block";
            formAppearance = false;
        } else {
            basket_form.style.display = "block";
            basket_letter.style.display = "none";
            formAppearance = true;
        }
    }
    function send(event) {
        event.preventDefault();
        var inputs = document.getElementById("basket_form").elements;
        var re = /^[\w]{1}[\w-\.]*@[\w-]+\.[a-z]{2,4}$/i;
        if (re.test(inputs[1].value)) {
            re = /^[\+][\d\ ]{10,15}\d$/;
            if (re.test(inputs[2].value)) {
                setBtn("Заказ формируется...");
                var data = {};
                for (let i = 0; i < inputs.length - 1; i++) {
                    data[inputs[i].name] = inputs[i].value;
                }
                var indexes = cookies.get("indexes");
                var items=[];
                var item;
                if (indexes !== undefined) {
                    indexes = indexes.split(";");
                    for (let i = 1; i < indexes.length; i++) {
                        item = cookies.get(indexes[i]);
                        items.push({name: item.name, count: item.amount});
                    }
                }
                data.products = items;
                console.log(data);
                axios.put('/api/user/ordercall', data)
                .then(res => {
                    setBtn("Заказ сформирован");
                    if (res.status === 200) {
                        formChanger();
                        cookies.remove("number", { path: '/' });
                        cookies.remove("things", { path: '/' });
                        cookies.remove("indexes", { path: '/' });
                        cookies.remove("is_checked", { path: '/' });
                        cookies.remove("amount", { path: '/' });
                    }
                })
                .catch((err) => {
                    setBtn("Ошибка при формировании заказа!");
                });
            } else {
                setBtn(`Введите телефон в правильном формате. Нажмите кнопку`);
            }
        } else {
            setBtn(`Введите e-mail в правильном формате. Нажмите кнопку`);
        }
    }
    return(
    <div>
        <form id="basket_form" className="basket_form" action="">
            <a className="form_cross" href="/basket">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" fill="none">
<path d="M31 1L0.999996 31" stroke="#35322A" strokeLinecap="round"/>
<path d="M31 31L1 1.00003" stroke="#35322A" strokeLinecap="round"/>
</svg>
            </a>
            <div className="form_inner">
                <div className="form_h">Оформление заказа</div>
                <div className="form_text">Укажите Ваши данные, чтобы мы смогли проинформировать вас о наличии позиций и способах доставки</div>
                <input name="name" type="text" placeholder="Ваше имя" required/>
                <input name="mail" type="email" placeholder="Ваш e-mail" required/>
                <input name="number" type="tel" placeholder="+7 --- --- -- --" required/>
                <input name="country" type="text" placeholder="Ваша страна" required/>
                <input name="city" type="text" placeholder="Ваш город" required/>
                <textarea name="comment" type="textarea" id="comment" placeholder="Комментарии"/>
                <div className="form_send" onClick={(event) => send(event)}>{btnText}</div>   
            </div> 
        </form>
        <div className="basket_form" id="basket_letter">
            <div className="form_cross" onClick={formChanger}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" fill="none">
<path d="M31 1L0.999996 31" stroke="#35322A" strokeLinecap="round"/>
<path d="M31 31L1 1.00003" stroke="#35322A" strokeLinecap="round"/>
</svg>
            </div>
            <div className="form_inner" id="">
                <div className="form_h">Ваш заказ оформлен!</div>
                <div className="form_text">
                Ваш заказ будет обработан в течение 2-3 дней. Скоро с Вами свяжется сотрудник магазина и уточнит всю необходимую информацию касательно наличия, оплаты и доставки. 
                Также Вы можете связаться с сотрудником магазина по телефону или в WhatsApp <a href="tel:+79998817625">+7(999)881-76-25</a>
                </div>
                <a className="form_send" href="/">Вернуться на главную</a>   
            </div> 
        </div>
    </div>
    );
}